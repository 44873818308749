import React from "react";
import "./index.css";
import CTAText from "../../../../components/CTAText";
import NavBar from "../../../../components/NavBar";
import OfferPageTitle from "../../../../components/OfferPageTitle";
import Footer from "../../../../components/Footer";
import { useNavItems } from "../useNavItems";
import SubPageNavigation from "../../../../components/SubPageNavigation";
import GalleryCarousel from "../../../../components/GalleryCarousel";
import { GALLERY_FORMY_WTRYSKOWE } from "../../../../store";

const Page = () => {
  const { navItems } = useNavItems();
  return (
    <>
      <NavBar />
      <div className="cmp-container">
        <OfferPageTitle title="Usługi projektowe" current="/oferta/uslugi-projektowe" />
        <SubPageNavigation
          navIems={navItems}
          img="/img/2.jpg"
          currentSection="formy-wtryskowe"
        >
          <section id="formy-wtryskowe">
            <h3>FORMY WTRYSKOWE</h3>
            <p>
              Projektowaniem form wtryskowych zajmuję się już ponad 20 lat. Moje
              bogate doświadczenie obejmuje detale od 1g do 3000g wtrysku.
            </p>
            <ul>
              <li>systemy gorącokanałowe, w tym zamykane i sekwencyjne</li>
              <li>wtrysk 2 komponentowy</li>
              <li>większość popularnych tworzyw technicznych</li>
              <li>
                praktyczna wiedza w zakresie wykonywania i interpretacji wyników
                symulacji wtrysku
              </li>
            </ul>
            <p>Swoją ofertę kieruję do narzędziowni, które:</p>
            <ul>
              <li>potrzebują dodatkowych mocy konstrukcyjnych</li>
              <li>
                szukają samodzielnego konstruktora dla konkretnego projektu
              </li>
              <li>
                oczekują wsparcia w ocenie technologiczności detali i
                przygotowania założeń do oferty
              </li>
            </ul>
            <p>
              Na życzenie mogę pośredniczyć w doborze komponentów (np. Systemy
              G-K), pozyskaniu i ocenie ofert.
            </p>
            <GalleryCarousel content={GALLERY_FORMY_WTRYSKOWE.content} />
            <CTAText text="Portfolio wysyłam na zapytanie" />
          </section>
        </SubPageNavigation>
      </div>
      <Footer />
    </>
  );
};

export default Page;
