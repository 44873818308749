import React from "react";
import { ReactComponent as IconDevelopment } from "./icon-development.svg";
import { ReactComponent as IconManagment } from "./icon-management.svg";
import { ReactComponent as IconProjectNew } from "./icon-project-new.svg";
import { ReactComponent as IconService } from "./icon-service.svg";

export type IconResolverProps = {
  icon: "development" | "management" | "project-new" | "service";
};

const IconResolver = ({ icon }: IconResolverProps) => {
  return (
    <>
      {icon === "development" && <IconDevelopment />}
      {icon === "management" && <IconManagment />}
      {icon === "project-new" && <IconProjectNew />}
      {icon === "service" && <IconService />}
    </>
  );
};

export default IconResolver;
