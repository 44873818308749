const useNavItems = () => {
  const navItems = [
    {
      label: "FORMY WTRYSKOWE",
      id: "formy-wtryskowe",
      href: '/oferta/uslugi-projektowe/formy-wtryskowe'
    },
    {
      label: "WKROJNIKI",
      id: "wkrojniki",
      href: '/oferta/uslugi-projektowe/wkrojniki'
    },
    {
      label: "MASZYNY",
      id: "maszyny",
      href: '/oferta/uslugi-projektowe/maszyny'
    },
    {
      label: "SYMULACJE",
      id: "symulacje",
      href: '/oferta/uslugi-projektowe/symulacje'
    },
  ];

  return { navItems };
};

export { useNavItems };
