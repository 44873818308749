export const NAV_ITEMS = [
  { label: "O firmie", href: "/#about" },
  { label: "Oferta", href: "/oferta" },
  { label: "Jak działamy", href: "/jak-dzialamy" },
  { label: "Galeria", href: "/galeria" },
  { label: "Kontakt", href: "/kontakt" },
];

export const OFFER_TILES = [
  {
    img: "/img/1.jpg",
    title: "Przyrządy produkcyjne",
    href: "/oferta/przyrzady-produkcyjne",
    body: (
      <>
        <ul>
          <li>Przyrządy kontrolno-pomiarowe</li>
          <li>Przyrządy montażowe</li>
          <li>Uchwyty i przyrządy obróbkowe</li>
          <li>Przyrządy spawalnicze</li>
          <li>Automatyzacja produkcji</li>
        </ul>
      </>
    ),
  },
  {
    img: "/img/2.jpg",
    title: "Usługi projektowe",
    href: "/oferta/uslugi-projektowe",
    body: (
      <>
        <ul>
          <li>Formy wtryskowe</li>
          <li>Wkrojniki</li>
          <li>Maszyny</li>
          <li>Symulacje</li>
        </ul>
      </>
    ),
  },
  {
    img: "/img/3.jpg",
    title: "Rozwój produktu",
    href: "/oferta/rozwoj-produktu",
    body: (
      <>
        <ul>
          <li>Projekty części i zespołów</li>
          <li>Analiza tolerancji</li>
          <li>Dobór materiałów i technologii wytworzenia</li>
          <li>Kalkulacja nakładów kosztów inwestycyjnych i wytworzenia</li>
          <li>Wdrożenie do produkcji</li>
        </ul>
      </>
    ),
  },
  {
    img: "/img/4.jpg",
    title: "Zarządzanie projektami",
    href: "/oferta/zarzadzanie-projektami",
    body: (
      <>
        <ul>
          <li>Od idei do pełnego wdrożenia</li>
          <li>Reprezentacja interesów klienta wobec dostawców</li>
          <li>Nadzór nad harmonogramem i budżetem</li>
        </ul>
      </>
    ),
  },
];

export type GalleryObject = Array<{
  id: string;
  label: string;
  thumb: string;
  content: { src: string; thumb?: string }[] | GalleryObject;
}>;

export const GALLERY_FORMY_WTRYSKOWE = {
  id: "zdjęcia-formy-wtryskowe",
  label: "Formy wtryskowe",
  thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma1_thumbnail.png",
  content: [
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma1.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma1_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma10.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma10_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma11.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma11_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma12.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma12_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma13.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma13_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma14.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma14_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma15.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma15_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma16.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma16_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma17.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma17_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma18.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma18_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma19.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma19_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma2.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma2_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma20.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma20_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma21.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma21_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma22.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma22_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma3.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma3_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma4.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma4_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma5.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma5_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma6.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma6_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma7.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma7_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma8.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma8_thumbnail.png",
    },
    {
      src: "/gallery/zdjęcia-formy-wtryskowe/Forma9.png",
      thumb: "/gallery/zdjęcia-formy-wtryskowe/Forma9_thumbnail.png",
    },
  ],
};

export const GALLERY_ZDJECIA_WKROJNIKI = {
  id: "zdjęcia-wkrojniki",
  label: "Zdjęcia wkrojniki",
  thumb: "/gallery/zdjęcia-wkrojniki/shutterstock_771994750_thumbnail.jpg",
  content: [
    {
      src: "/gallery/zdjęcia-wkrojniki/shutterstock_1579086397.jpg",
      thumb: "/gallery/zdjęcia-wkrojniki/shutterstock_1579086397_thumbnail.jpg",
    },
    {
      src: "/gallery/zdjęcia-wkrojniki/shutterstock_2302699159.jpg",
      thumb: "/gallery/zdjęcia-wkrojniki/shutterstock_2302699159_thumbnail.jpg",
    },
    {
      src: "/gallery/zdjęcia-wkrojniki/shutterstock_771994750.jpg",
      thumb: "/gallery/zdjęcia-wkrojniki/shutterstock_771994750_thumbnail.jpg",
    },
  ],
};

export const GALLERY_AUTOMATYZACJA = {
  id: "automatyzacja",
  label: "Automatyzacja",
  thumb: "/gallery/automatyzacja/shutterstock_614126777_thumbnail.jpg",
  content: [
    {
      src: "/gallery/automatyzacja/shutterstock_2188603541.jpg",
      thumb: "/gallery/automatyzacja/shutterstock_2188603541_thumbnail.jpg",
    },
    {
      src: "/gallery/automatyzacja/shutterstock_2190510397.jpg",
      thumb: "/gallery/automatyzacja/shutterstock_2190510397_thumbnail.jpg",
    },
    {
      src: "/gallery/automatyzacja/shutterstock_2229495595.jpg",
      thumb: "/gallery/automatyzacja/shutterstock_2229495595_thumbnail.jpg",
    },
    {
      src: "/gallery/automatyzacja/shutterstock_614126777.jpg",
      thumb: "/gallery/automatyzacja/shutterstock_614126777_thumbnail.jpg",
    },
  ],
};

export const GALLERY_PRZYRZADY_KONTROLNO_POMIAROWE = {
  id: "przyrzady-kontrolno-pomiarowe",
  label: "Przyrządy kontrolno pomiarowe",
  thumb: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_01_1_thumbnail.png",
  content: [
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_00_a99.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_00_a99_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_01_1.png",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_01_1_thumbnail.png",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_01_2.png",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_01_2_thumbnail.png",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_01_3.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_01_3_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_01_4.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_01_4_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_01_5.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_01_5_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_01_6.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_01_6_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_01_7.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_01_7_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_01_8.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_01_8_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_02_1.png",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_02_1_thumbnail.png",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_03_1.png",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_03_1_thumbnail.png",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_03_2.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_03_2_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_03_3.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_03_3_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_03_4.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_03_4_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_04_1.png",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_04_1_thumbnail.png",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_05_1.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_05_1_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_05_2.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_05_2_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_06_1.png",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_06_1_thumbnail.png",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_06_2.png",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_06_2_thumbnail.png",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_06_3.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_06_3_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_06_4.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_06_4_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_06_5.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_06_5_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_06_6.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_06_6_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_07_1.png",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_07_1_thumbnail.png",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_07_2.png",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_07_2_thumbnail.png",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_07_3.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_07_3_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_07_4.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_07_4_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_08_1.png",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_08_1_thumbnail.png",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_08_2.png",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_08_2_thumbnail.png",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_09_1.png",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_09_1_thumbnail.png",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_10_1.png",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_10_1_thumbnail.png",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_11_1.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_11_1_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_11_2.png",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_11_2_thumbnail.png",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_11_3.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_11_3_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_11_4.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_11_4_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_11_5.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_11_5_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_12_1.png",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_12_1_thumbnail.png",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_12_2.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_12_2_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_12_3.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_12_3_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_12_4.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_12_4_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_12_5.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_12_5_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_12_6.jpg",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_12_6_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_13.png",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_13_thumbnail.png",
    },
    {
      src: "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_99_raport.JPG",
      thumb:
        "/gallery/przyrzady-kontrolno-pomiarowe/Sprawdzian_99_raport_thumbnail.JPG",
    },
  ],
};

export const GALLERY_PRZYRZADY_SPAWALNICZE = {
  id: "przyrzady-spawalnicze",
  label: "Przyrządy spawalnicze",
  thumb: "/gallery/przyrzady-spawalnicze/shutterstock_2062013957_thumbnail.jpg",
  content: [
    {
      src: "/gallery/przyrzady-spawalnicze/shutterstock_2062013957.jpg",
      thumb:
        "/gallery/przyrzady-spawalnicze/shutterstock_2062013957_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-spawalnicze/shutterstock_409910494.jpg",
      thumb:
        "/gallery/przyrzady-spawalnicze/shutterstock_409910494_thumbnail.jpg",
    },
    {
      src: "/gallery/przyrzady-spawalnicze/shutterstock_719781577.jpg",
      thumb:
        "/gallery/przyrzady-spawalnicze/shutterstock_719781577_thumbnail.jpg",
    },
  ],
};

export const GALLERY_UCHWYTY_OBROBKOWE = {
  id: "uchwyty-obrobkowe",
  label: "Uchwyty obróbkowe",
  thumb: "/gallery/uchwyty-obrobkowe/Milling_fixture_CNC1_thumbnail.JPG",
  content: [
    {
      src: "/gallery/uchwyty-obrobkowe/Milling_fixture_CNC1.JPG",
      thumb: "/gallery/uchwyty-obrobkowe/Milling_fixture_CNC1_thumbnail.JPG",
    },
    {
      src: "/gallery/uchwyty-obrobkowe/Milling_fixture_CNC2.JPG",
      thumb: "/gallery/uchwyty-obrobkowe/Milling_fixture_CNC2_thumbnail.JPG",
    },
    {
      src: "/gallery/uchwyty-obrobkowe/Milling_fixture_CNC3.jpg",
      thumb: "/gallery/uchwyty-obrobkowe/Milling_fixture_CNC3_thumbnail.jpg",
    },
    {
      src: "/gallery/uchwyty-obrobkowe/shutterstock_1073509100.jpg",
      thumb: "/gallery/uchwyty-obrobkowe/shutterstock_1073509100_thumbnail.jpg",
    },
  ],
};

export const GALLERY: GalleryObject = [
  GALLERY_FORMY_WTRYSKOWE,
  GALLERY_PRZYRZADY_KONTROLNO_POMIAROWE,
  GALLERY_PRZYRZADY_SPAWALNICZE,
  GALLERY_UCHWYTY_OBROBKOWE,
  GALLERY_ZDJECIA_WKROJNIKI,
  GALLERY_AUTOMATYZACJA,
];
