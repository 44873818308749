import React from "react";
import "./index.css";

type Props = {
  display: boolean;
};

const DefaultCard = ({ display }: Props) => {
  const defaultMiddleTitle =
    "Projektowanie i dostawa indywidulanych przyrządów produkcyjnych dopasowanych do twoich potrzeb";
  const defaultBottomTitle =
    "Indywidualne podejście doświadczenie rzetelność zaufanie";
  const displayClass = display ? "cmp-img-carousel__card--display" : "";
  return (
    <div
      className={`cmp-img-carousel__card cmp-img-carousel__card--default ${displayClass}`}
    >
      <img src="/img/0.jpg" alt="" />
      <div className="cmp-img-carousel__card-default-title-container">
        <h2 className="cmp-img-carousel__card-default-title">
          {defaultMiddleTitle}
        </h2>
        <h3 className="cmp-img-carousel__card-default-subtitle">
          {defaultBottomTitle}
        </h3>
      </div>
    </div>
  );
};

export default DefaultCard;
