import React from "react";
import "./pickerSection.css";

type CarouselPickerSectionProps = {
  contents: {
    img: string;
    title: string;
  }[];
  seleted: string | undefined;
  handleImageSelection: (img: string) => void;
};

const CarouselPickerSection = ({
  contents,
  seleted,
  handleImageSelection,
}: CarouselPickerSectionProps) => {
  return (
    <div className="cmp-img-carousel__picker">
      {contents &&
        contents.map((src) => (
          <div
            className={
              src.img === seleted
                ? "cmp-img-carousel__img cmp-img-carousel__img--checked"
                : "cmp-img-carousel__img"
            }
            key={`picker-${src.img}`}
          >
            <h3>{src.title}</h3>
            <img
              onClick={() => handleImageSelection(src.img)}
              alt=""
              src={src.img}
            />
          </div>
        ))}
    </div>
  );
};

export default CarouselPickerSection;
