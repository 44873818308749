import React from "react";
import "./index.css";

const GeneralCompanyDetail = () => {
  return (
    <div className="cmp-general-company-detail">
      <h3>DI-TECH Dariusz Izydorczyk</h3>
      <h4>923-131-28-56</h4>
    </div>
  );
};

export default GeneralCompanyDetail;
