import React from "react";
import { ReactComponent as EmailLogo } from "./email-svgrepo-com.svg";
// import { ReactComponent as NIPLogo } from "./id-card-svgrepo-com.svg";
import { ReactComponent as LocationLogo } from "./location-pin-alt-svgrepo-com.svg";
import { ReactComponent as PhoneLogo } from "./phone-rounded-svgrepo-com.svg";
import "./index.css";

const ContanctDetails = () => {
  // const nip = "923-131-28-56";
  const location = "64-200 Wolsztyn, źródlana 7";
  const phone = "606 710 695";
  const email = "biuro@di-tech.pl";

  return (
    <div className="cmp-contact-details">
      <div className="cmp-contact-details__detail">
        <EmailLogo />
        <h2>Email</h2>
        <a href={`mailto:${email}`}>{email}</a>
      </div>
      <div className="cmp-contact-details__detail">
        <PhoneLogo />
        <h2>Telefon</h2>
        <a href={`phone:${phone}`}>{phone}</a>
      </div>
      <div className="cmp-contact-details__detail">
        <LocationLogo />
        <h2>Adres</h2>
        <span>{location}</span>
      </div>
      {/* <div className="cmp-contact-details__detail cmp-contact-details__nip">
        <NIPLogo />
        <h2>NIP</h2>
        <span>{nip}</span>
      </div> */}
    </div>
  );
};

export default ContanctDetails;
