import React from "react";

type Props = {
  content: {
    img: string;
    title: string;
    href: string;
    body: JSX.Element;
  };
  display: boolean;
};

const ContentCard = ({ content, display }: Props) => {
  const displayClass = display ? "cmp-img-carousel__card--display" : "";
  return (
    <div
      className={`cmp-img-carousel__card cmp-img-carousel__card--content ${displayClass}`}
    >
      <img src={content.img} alt="" />
      <div className="cmp-img-carousel__card-content-container">
        <h2>{content.title}</h2>
        <p>{content.body}</p>
        <a href={content.href}>Więcej</a>
      </div>
      <div className="cmp-img-carousel__backdrop"></div>
    </div>
  );
};

export default ContentCard;
