import React, { useState } from "react";
import "./index.css";
import Navigation from "./Navigation";

type SubPageNavigationProps = React.PropsWithChildren<{
  navIems: { label: string; id: string; href: string }[];
  img: string;
  currentSection: string | [string, string];
}>;

/**
 * @param param0 nav items, current page id and image to disaply
 * @returns JSX Element
 */
const SubPageNavigation = ({
  img,
  navIems,
  children,
  currentSection,
}: SubPageNavigationProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="cmp-section-with-navigation">
      <div
        className={`cmp-section-with-navigation__nav ${
          isOpen ? "cmp-section-with-navigation__nav--open" : ""
        }`}
      >
        <span
          onClick={() => setIsOpen(!isOpen)}
          className="cmp-section-with-navigation__mobile-button"
        >
          {isOpen ? 'Zamknij spis treści' : 'Spis treści'}
        </span>
        <Navigation
          img={img}
          currentSection={currentSection}
          navIems={navIems}
        />
      </div>
      <div className="cmp-section-with-navigation__content">{children}</div>
    </div>
  );
};

export default SubPageNavigation;
