import React from "react";
import { ReactComponent as SmallLogoSVG } from "./icon-small.svg";
import "./index.css";

const SmallLogo = () => {
  return (
    <div className="cmp-small-logo">
      <SmallLogoSVG />
    </div>
  );
};

export default SmallLogo;
