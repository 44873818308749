const useNavItems = () => {
  const navItems = [
    {
      label: "PRZYRZĄDY KONTROLNO-POMIAROWE",
      id: "sprawdziany-kontrolno-pomiarowe",
      href: "/oferta/przyrzady-produkcyjne/przyrzady-kontrolno-pomiarowe",
    },
    {
      label: "PRZYRZĄDY MONTAŻOWE",
      id: "przyrzady-montazowe",
      href: "/oferta/przyrzady-produkcyjne/przyrzady-montazowe",
    },
    {
      label: "UCHWYTY I PRZYRZĄDY OBRÓBKOWE",
      id: "uchwyty-i-przyrzady-obrobkowe",
      href: "/oferta/przyrzady-produkcyjne/uchwyty-i-przyrzady-obrobkowe",
    },
    {
      label: "PRZYRZĄDY SPAWALNICZE",
      id: "przyrzady-spawalnicze",
      href: "/oferta/przyrzady-produkcyjne/przyrzady-spawalnicze",
    },
    {
      label: "AUTOMATYZACJA PRODUKCJI",
      id: "automatyzacja-produkcji",
      href: "/oferta/przyrzady-produkcyjne/automatyzacja-produkcji",
    },
  ];

  return { navItems };
};

export { useNavItems };
