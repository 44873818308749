import React from "react";
import "./index.css";

type RightImageHeaderProps = {
  text: string;
};

const RightImageHeader = ({ text }: RightImageHeaderProps) => {
  return (
    <header className="cmp-right-image-header">
      <h1>{text}</h1>
      <img alt="" src="/img/4.jpg" />
    </header>
  );
};

export default RightImageHeader;
