import React from "react";
import "./index.css";
import TextTiles from "../../../../components/TextTiles";
import CTAText from "../../../../components/CTAText";
import NavBar from "../../../../components/NavBar";
import OfferPageTitle from "../../../../components/OfferPageTitle";
import Footer from "../../../../components/Footer";
import { useNavItems } from "../useNavItems";
import SubPageNavigation from "../../../../components/SubPageNavigation";

const Page = () => {
  const { navItems } = useNavItems();
  return (
    <>
      <NavBar />
      <div className="cmp-container">
        <OfferPageTitle
          title="Przyrządy produkcyjne"
          current="/oferta/przyrzady-produkcyjne"
        />
        <SubPageNavigation
          navIems={navItems}
          img="/img/1.jpg"
          currentSection="przyrzady-montazowe"
        >
          <section id="przyrzady-montazowe">
            <h3>PRZYRZĄDY MONTAŻOWE</h3>
            <p>Są wykorzystywane wszędzie tam, gdzie zależy nam na:</p>
            <ul>
              <li>poprawie jakości montażu</li>
              <li>przyśpieszenia procesu</li>
              <li>zastąpienia pracy człowieka</li>
              <li>łączenie wielu operacji</li>
              <li>eliminacji zbędnych operacji</li>
            </ul>
            <p>Przyrządy montażowe mogą być:</p>
            <ul>
              <li>ręczne</li>
              <li>półautomatyczne</li>
              <li>zautomatyzowane</li>
            </ul>
            <p>
              Indywidualna analiza procesu technologicznego pozwala na
              odpowiednie zaprojektowanie i wykonanie przyrządów, które będą
              spełniały wszystkie istotne funkcje, przy zachowaniu odpowiedniego
              poziomu nakładów inwestycyjnych i spodziewanego poziomu jakości.
              Idealne w optymalizacji produkcji LEAN MANUFACTURING i przy
              balansowaniu linii produkcyjnych.
            </p>
            <TextTiles
              texts={[
                "ROZWIĄZANIE SZYTE NA MIARĘ POTRZEB",
                "ERGONOMIA I BHP",
                "MINIMUM KOSZTÓW MAXIMUM JAKOŚCI",
              ]}
            />
            <CTAText text="POKAŻ SWOJE POTRZEBY A ZAPROPONUJEMY OPTYMALNE ROZWIĄZANIE" />
          </section>
        </SubPageNavigation>
      </div>
      <Footer />
    </>
  );
};

export default Page;
