import "./index.css";

type Props = {
  content: {
    signature: string;
    title: string;
    body: JSX.Element;
  }[];
};

const DesktopList = ({ content }: Props) => {
  return (
    <div className="cmp-signature-step-list">
      {content.map((item) => (
        <div
          className="cmp-signature-step-list__step-container"
          key={`${item.signature}-${item.title}`}
        >
          {item.signature && (
            <div className="cmp-signature-step-list__signature-container">
              <span className="cmp-signature-step-list__signature">
                {item.signature}
              </span>
            </div>
          )}

          <div className="cmp-signature-step-list__step">
            <div className="cmp-signature-step-list__col"></div>
            <div className="cmp-signature-step-list__col">
              <h4>{item.title}</h4>
              {item.body}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DesktopList;
