import { PhotoProvider, PhotoView } from "react-photo-view";

import { ReactComponent as ArrowLeftIcon } from "./arrow-left-2-svgrepo-com.svg";

import "./index.css";
import { useEffect, useRef } from "react";

type Props = {
  content: { src: string; thumb?: string }[];
};

const GALLERY_CAROUSEL_SCROLL_BY_OFFSET = 200;

const GalleryCarousel = ({ content }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleNextSlie = () => {
    if (!ref) {
      console.warn("Gallery Carusel refrence not found");
      return;
    }

    ref.current?.scrollBy(GALLERY_CAROUSEL_SCROLL_BY_OFFSET, 0);
  };

  const handlePreviousSlie = () => {
    if (!ref) {
      console.warn("Gallery Carusel refrence not found");
      return;
    }

    ref.current?.scrollBy(-GALLERY_CAROUSEL_SCROLL_BY_OFFSET, 0);
  };

  useEffect(() => {
    setInterval(handleNextSlie, 3000);
  }, []);

  return (
    <div className="cmp-gallery-carousel">
      <button
        className="cmp-gallery-carousel__arrow cmp-gallery-carousel__arrow-left"
        onClick={handlePreviousSlie}
      >
        <ArrowLeftIcon />
      </button>
      <div className="cmp-gallery-carousel__container" ref={ref}>
        <PhotoProvider>
          {content.map((img) => (
            <div key={img.src} className="cmp-gallery-carousel__item">
              <PhotoView src={img.src}>
                <img alt="" src={img.thumb} />
              </PhotoView>
            </div>
          ))}
        </PhotoProvider>
      </div>
      <button
        className="cmp-gallery-carousel__arrow cmp-gallery-carousel__arrow-right"
        onClick={handleNextSlie}
      >
        <ArrowLeftIcon />
      </button>
    </div>
  );
};

export default GalleryCarousel;
