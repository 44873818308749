import React from "react";
import "./index.css";
import SmallLogo from "../SmallLogo";
import { NAV_ITEMS } from "../../store";

const Footer = () => {
  const copyright = "© Ditech 2023";

  return (
    <footer className="cmp-footer">
      <a href="/">
        <SmallLogo />
      </a>
      <nav>
        {NAV_ITEMS.map((item) => (
          <a key={item.href} href={item.href}>
            {item.label}
          </a>
        ))}
      </nav>
      <span className="cmp-footer__copyright">{copyright}</span>
    </footer>
  );
};

export default Footer;
