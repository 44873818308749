import React from "react";
import "./index.css";

type NavigationProps = {
  navIems: { label: string; id: string; href: string }[];
  currentSection: string | [string, string] | undefined;
  img: string;
};

const Navigation = ({
  navIems,
  currentSection,
  img,
}: NavigationProps) => {
  const resolveClassName = (
    id: string,
    cur: string | [string, string] | undefined
  ) => {
    if (!cur) {
      return "";
    }
    if (typeof cur === "string") {
      return id === cur ? "cmp-section-with-navigation__item--selected" : "";
    }

    return cur.includes(id)
      ? "cmp-section-with-navigation__item--selected cmp-section-with-navigation__item--doubled"
      : "";
  };
  return (
    <div className="cmp-section-with-navigation__nav-body">
      <img src={img} alt="" />
      {navIems.map((item) => (
        <a
          className={resolveClassName(item.id, currentSection)}
          key={item.id}
          href={item.href}
        >
          {item.label}
        </a>
      ))}
    </div>
  );
};

export default Navigation;
