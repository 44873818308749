import React from "react";
import "./index.css";
import CTAText from "../../../../components/CTAText";
import NavBar from "../../../../components/NavBar";
import OfferPageTitle from "../../../../components/OfferPageTitle";
import Footer from "../../../../components/Footer";
import { useNavItems } from "../useNavItems";
import SubPageNavigation from "../../../../components/SubPageNavigation";
import GalleryCarousel from "../../../../components/GalleryCarousel";
import { GALLERY_ZDJECIA_WKROJNIKI } from "../../../../store";

const Page = () => {
  const { navItems } = useNavItems();
  return (
    <>
      <NavBar />
      <div className="cmp-container">
        <OfferPageTitle title="Usługi projektowe" current="/oferta/uslugi-projektowe" />
        <SubPageNavigation
          navIems={navItems}
          img="/img/2.jpg"
          currentSection="wkrojniki"
        >
          <section id="wkrojniki">
            <h3>WYKROJNIKI</h3>
            <p>
              Od prostych aplikacji 1-taktowych do szybkobieżnych wykrojników
              postępowych. Posiadamy doświadczenie w wykrawaniu detali z tworzyw
              sztucznych.
            </p>
            <p>
              Oferta specjalna dedykowana narzędziowniom posiadającym
              doświadczenie w produkcji wykrojników.
            </p>
            <GalleryCarousel content={GALLERY_ZDJECIA_WKROJNIKI.content} />
            <CTAText text="Zapraszam do kontaktu" />
          </section>
        </SubPageNavigation>
      </div>
      <Footer />
    </>
  );
};

export default Page;
