import React from "react";
import "./index.css";
import CTAText from "../../../../components/CTAText";
import NavBar from "../../../../components/NavBar";
import OfferPageTitle from "../../../../components/OfferPageTitle";
import Footer from "../../../../components/Footer";
import { useNavItems } from "../useNavItems";
import SubPageNavigation from "../../../../components/SubPageNavigation";

const Page = () => {
  const { navItems } = useNavItems();
  return (
    <>
      <NavBar />
      <div className="cmp-container">
        <OfferPageTitle title="Usługi projektowe" current="/oferta/uslugi-projektowe" />
        <SubPageNavigation
          navIems={navItems}
          img="/img/2.jpg"
          currentSection="maszyny"
        >
          <section id="maszyny">
            <h3>MASZYNY</h3>
            <p>
              Projektujemy maszyny realizujące lub wspomagające procesy
              montażowe wyrobów.
            </p>
            <ul>
              <li>
                Aplikacje oparte o stoły obrotowe i/lub liniowe systemy
                transportowe pozwalające na podzielenie procesu montażowego na
                pojedyncze operacje technologiczne.
              </li>
              <li>
                Kontrola jakości wbudowana w proces technologiczny na podstawie
                analizy ryzyka P-FMEA
              </li>
              <li>Kontrola jakości gotowego wyrobu (kontrola wizyjna)</li>
              <li>
                Analiza i statystyka procesu na bazie danych zbieranych w
                trakcie pracy maszyny
              </li>
            </ul>
            <p>
              Usługi projektowe kierowane do klientów posiadających możliwości
              wykonania we własnym zakresie.
            </p>
            <CTAText text="DLA WYBRANYCH APLIKACJI OFERUJEMY PROJEKT I DOSTAWĘ GOTOWEGO DO UŻYCIA ROZWIĄZANIA." />
          </section>
        </SubPageNavigation>
      </div>
      <Footer />
    </>
  );
};

export default Page;
