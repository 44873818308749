import React from "react";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import "./index.css";
import OfferPageTitle from "../../../components/OfferPageTitle";

const OfferProductDevelopmentPage = () => {
  return (
    <>
      <NavBar />
      <div className="cmp-container">
        <OfferPageTitle
          title="Rozwoj produktu"
          current="/oferta/rozwoj-produktu"
        />
        <section>
          <p>
            Specjalizujemy się w projektowaniu detali z tworzyw sztucznych
            produkowanych metodą wtrysku oraz części metalowych. Mamy
            wieloletnie doświadczenie w projektowaniu wyrobów i wdrażaniu ich do
            produkcji seryjnej z pełną automatyzacją włącznie. Dzięki temu od
            samego początku projekt uwzględnia wymagania procesu produkcyjnego
            co czyni go prostszym i tańszym.
          </p>
          <ul>
            <li>Chcesz zmienić materiał z metalu na tworzywo?</li>
            <li>Potrzebujesz obudowy dla swojego urządzenia?</li>
            <li>Masz pomysł na produkt ale nie masz własnego działu R&D?</li>
            <li>Kupujesz a chciałbyś produkować sam?</li>
          </ul>
          <p>
            Jesteśmy w stanie pomóc przejmując odpowiedzialność za część lub
            całość procesu od idei do wdrożenia.
          </p>
          <ul>
            <li>Razem spisujemy wymagania dla nowego wyrobu</li>
            <li>
              Projekt uwzględnia wymagania procesu wtrysku &#40;pochylenia
              odlewnicze, płyniecie tworzywa, eliminacja wad produkcyjnych
              itp.&#41;
            </li>
            <li>Dobór materiału</li>
            <li>Oszacowanie kosztów produkcji</li>
            <li>Koncepcja, prototypowanie, optymalizacja</li>
          </ul>
          <p>
            Nasza współpraca może obejmować wybrany wycinek lub całość procesu
            wdrożenia nowego wyrobu. Ty decydujesz.
          </p>
          <p>
            Masz pomysł na nowy wyrób, ale nie masz wiedzy i zaplecza
            produkcyjnego? Rozwiązanie jest proste. Powierzasz nam pełne
            wdrożenie od koncepcji do uruchomienia produkcji seryjnej włącznie,
            w tym nasze wsparcie w poszukiwaniu kooperantów i zakupie środków
            produkcji.
          </p>
          <p>To jest prostsze niż myślisz. Porozmawiajmy.</p>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default OfferProductDevelopmentPage;
