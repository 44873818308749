import React from "react";
import "./index.css";
import CTAText from "../../../../components/CTAText";
import NavBar from "../../../../components/NavBar";
import OfferPageTitle from "../../../../components/OfferPageTitle";
import Footer from "../../../../components/Footer";
import { useNavItems } from "../useNavItems";
import SubPageNavigation from "../../../../components/SubPageNavigation";

const Page = () => {
  const { navItems } = useNavItems();
  return (
    <>
      <NavBar />
      <div className="cmp-container">
        <OfferPageTitle title="Usługi projektowe" current="/oferta/uslugi-projektowe" />
        <SubPageNavigation
          navIems={navItems}
          img="/img/2.jpg"
          currentSection="symulacje"
        >
          <section id="symulacje">
            <h3>SYMULACJE</h3>
            <p>
              Komputerowe analizy wytrzymałościowe i/lub symulacje procesów to
              dokonały sposób na weryfikację założeń konstrukcyjnych przed fazą
              testowania prototypu.
            </p>
            <p>Pozwala zminimalizować koszty i czas wdrożenia.</p>
            <ul>
              <li>Symulacje wtrysku tworzywa</li>
              <li>Analizy płynięcia</li>
              <li>Analizy wytrzymałościowe</li>
              <li>inne</li>
            </ul>
            <p>
              Część analiz wykonujemy samodzielnie, dla innych
              wysokospecjalistycznych wspieramy się sprawdzonymi specjalistami.
            </p>
            <CTAText text="OFERTA KIEROWANA DO WSZYSTKICH ZAJMUJĄCYCH SIĘ PROJEKTOWANIEM I WDROŻENIEM DO PRODUKCJI NOWYCH WYROBÓW" />
          </section>
        </SubPageNavigation>
      </div>
      <Footer />
    </>
  );
};

export default Page;
