import React from "react";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import "./index.css";
import OfferPageTitle from "../../../components/OfferPageTitle";

const OfferProjectManagmentPage = () => {
  return (
    <>
      <NavBar />
      <div className="cmp-container">
        <OfferPageTitle
          title="Zarządzanie projektami"
          current="/oferta/zarzadzanie-projektami"
        />
        <section>
          <p>
            Czy miałeś sytuację, kiedy musiałeś kupić coś na czym się nie znasz?
            Wybór jest tak duży, że trudno ocenić czego potrzebujesz? Masz
            wrażenie, że sprzedawca chce ci wcisnąć coś czego nie potrzebujesz?
            Jeśli twoja odpowiedź brzmi tak, to jesteś w odpowiednim miejscu.
          </p>
          <p>
            Wynajmij Inżyniera projektu, który będzie działała w twoim imieniu i
            interesie. Będzie reprezentował ciebie w realizacji projektu tak jak
            został on zdefiniowany w stworzonej przy twoim udziale specyfikacji
            wymagań Stworzy harmonogram i budżet, który Ty zatwierdzisz
            Poprowadzi projekt zgodnie z harmonogramem i będzie raportował
            postępy
          </p>
          <p>Przykładowy zakres projektu:</p>
          <ul>
            <li>
              <strong>Zaprojektowanie nowego wyrobu</strong> -{" "}
              <a href="/oferta/rozwoj-produktu">
                patrz zakładka ROZWÓJ PRODUKTU
              </a>
            </li>
            <li>
              <strong>Wdrożenie produkcji</strong> - Nowy produkt to szereg
              nowych narzędzi i przyrządów. Zaprojektujemy proces produkcyjny,
              zdefiniujemy niezbędne zakupy środków produkcji, pozyskamy oferty
              i poprowadzimy cały proces zakupu do uruchomienia produkcji
              włącznie.
            </li>
            <li>
              <strong>
                Zakup narzędzi &#40;formy wtryskowe, wykrojniki&#41;
              </strong>{" "}
              - Oferujemy wsparcie w zakresie zdefiniowania wymagań, pozyskania
              i oceny ofert, zatwierdzenia koncepcji i udział w odbiorze
              narzędzi.
            </li>
            <li>
              <strong>Zakup maszyn specjalnych</strong> - Maszyny specjalne to
              takie, które są projektowane i produkowane na twoje zamówienie. O
              ile kupując gotową maszynę da się porównać ofertę z tym co oferuje
              konkurencja, tak w przypadku maszyn specjalnych każdy dostawca
              będzie miał swój pomysł jak spełnić postawione wymagania. Zajmiemy
              się stworzenie specyfikacji wymagań, przekażemy je potencjalnym
              dostawcom, ocenimy oferty i pomożemy w wyborze dostawcy. Po
              zamówieniu będziemy nadzorować etap produkcji aż do końcowego
              odbioru.
            </li>
          </ul>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default OfferProjectManagmentPage;
