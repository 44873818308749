import React from "react";
import "./index.css";
import IconResolver from "./IconResolver";

export type OfferCardItemProps = {
  icon: "development" | "management" | "project-new" | "service";
  title: string;
  body?: JSX.Element;
  link: {
    label: string;
    href: string;
  };
};

const OfferCardItem = ({ icon, title, link, body }: OfferCardItemProps) => {
  return (
    <div className="cmp-offer-card">
      <IconResolver icon={icon} />
      <h3>{title}</h3>
      {body && <div className="cmp-offer-card__body">{body}</div>}
      <a href={link.href}>{link.label}</a>
    </div>
  );
};

export default OfferCardItem;
