import React, { useState } from "react";
import "./index.css";
import HamburgerButton from "../HamburgerButton";
import ResponsiveLogo from "../ResponsiveLogo";
import { NAV_ITEMS } from "../../store";

const NavBar = () => {
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  return (
    <nav className="cmp-navbar">
      <div className="cmp-navbar__container">
        <a href="/">
          <ResponsiveLogo />
        </a>
        <div className="cmp-navbar__links">
          {NAV_ITEMS.map((item) => (
            <a key={item.href} href={item.href}>
              {item.label}
            </a>
          ))}
        </div>
        <div className="cmp-navbar__hamburger">
          <HamburgerButton onClick={() => setIsMobileOpen(!isMobileOpen)} />
          {isMobileOpen && (
            <div className="cmp-navbar__links--mobile">
              {NAV_ITEMS.map((item) => (
                <a
                  key={item.href}
                  href={item.href}
                  onClick={() => setIsMobileOpen(false)}
                >
                  {item.label}
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
