import React from "react";
import ImgCarousel from "../components/ImgCarousel";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import AboutCompany from "../components/AboutCompany";
import OfferCards from "../components/OfferCards";

const MainPage = () => {
  return (
    <>
      <NavBar />
      <section id="carousel">
        <ImgCarousel />
      </section>
      <section id="about">
        <AboutCompany />
        <OfferCards />
      </section>
      <Footer />
    </>
  );
};

export default MainPage;
