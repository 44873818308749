import React from "react";
import "./index.css";
import TextTiles from "../../../../components/TextTiles";
import CTAText from "../../../../components/CTAText";
import NavBar from "../../../../components/NavBar";
import OfferPageTitle from "../../../../components/OfferPageTitle";
import Footer from "../../../../components/Footer";
import SubPageNavigation from "../../../../components/SubPageNavigation";
import { useNavItems } from "../useNavItems";
// import SingleImage from "../../../../components/SingleImage";
import GalleryCarousel from "../../../../components/GalleryCarousel";
import { GALLERY_PRZYRZADY_KONTROLNO_POMIAROWE } from "../../../../store";

const Page = () => {
  const { navItems } = useNavItems();
  return (
    <>
      <NavBar />
      <div className="cmp-container">
        <OfferPageTitle
          title="Przyrządy produkcyjne"
          current="/oferta/przyrzady-produkcyjne"
        />
        <SubPageNavigation
          navIems={navItems}
          img="/img/1.jpg"
          currentSection={[
            "sprawdziany-kontrolno-pomiarowe",
            "uchwyty-na-maszyne-cmm",
          ]}
        >
          <section id="sprawdziany-kontrolno-pomiarowe">
            <h3>PRZYRZĄDY KONTROLNO-POMIAROWE</h3>
            <p>
              są powszechnie stosowane do szybkiej weryfikacji kluczowych
              charakterystyk produktu bezpośrednio w procesie produkcyjnym.
              Prawidłowo zaprojektowany i wykonany sprawdzian cechują:
            </p>
            <ul>
              <li>powtarzalność montażu i pomiarów</li>
              <li>dokładność</li>
              <li>prostota pomiaru</li>
              <li>interpretacji wyniku</li>
            </ul>
          </section>
          <section id="uchwyty-na-maszyne-cmm">
            <h3>UCHWYTY NA MASZYNĘ CMM</h3>
            <p>
              są jedną z odmian przyrządów kontrolno-pomiarowych służące do
              szybkiego i powtarzalnego pozycjonowania detalu o skomplikowanej
              geometrii w celu wykonania pomiarów. Często te 2 funkcje się łączy
              i powstaje przyrząd który służy do weryfikacji produkcji w toku
              oraz do okresowej pełnej kontroli wymiarowej na maszynach
              pomiarowych
            </p>
            {/* <SingleImage src="/gallery/zdjęcia-wsparcie-produkcji/sprawdziany/Sprawdzian_01_1.png" /> */}
            <GalleryCarousel
              content={GALLERY_PRZYRZADY_KONTROLNO_POMIAROWE.content}
            />
            <TextTiles
              texts={[
                "ŁATWY I POWTARZALNY MONTAŻ DETALU",
                "PROSTE NRZĘDZIA POMIAROWE",
                "JEDNOZNACZNY WYNIK POMIARU",
              ]}
            />
            <CTAText text="POKAŻ SWÓJ DETAL A ZAPROPONUJEMY OPTYMALNE ROZWIĄZANIE" />
          </section>
        </SubPageNavigation>
      </div>
      <Footer />
    </>
  );
};

export default Page;
