import React from "react";
import { ReactComponent as HamburgerSVG } from "./burger-menu-svgrepo-com.svg";
import "./index.css";

type HamburgerButtonProps = {
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
};

const HamburgerButton = ({ onClick }: HamburgerButtonProps) => {
  return (
    <div className="cmp-hamburger-button" onClick={onClick}>
      <HamburgerSVG />
    </div>
  );
};

export default HamburgerButton;
