import React from "react";
import "./index.css";
import OfferTileItem from "../OfferTileItem";
import { OFFER_TILES } from "../../store";

const OfferTiles = () => {
  return (
    <div className="cmp-offer-tiles">
      {OFFER_TILES.map((tile) => (
        <OfferTileItem
          key={tile.title}
          title={tile.title}
          img={tile.img}
          href={tile.href}
        >
          {tile.body}
        </OfferTileItem>
      ))}
    </div>
  );
};

export default OfferTiles;
