import React from "react";
import "./index.css";

const AboutCompany = () => {
  return (
    <div className="cmp-about-company">
      <h2>O firmie</h2>
      <p>
        Bogate doświadczenie zawodowe w konstrukcji maszyn, form i przyrządów
        zaowocowało założeniem firmy DI-TECH w 2011 roku.
      </p>
      <p>
        Początki firmy to usługi projektowe dla narzędziowni – szeroka gama
        narzędzi, przyrządów i maszyn dla różnych branż.
      </p>
      <p>
        Rynek nie znosi pustki – a co z klientami, którzy nie mają własnego
        parku maszynowego?
      </p>
      <p>
        I tak narodziła się dominująca część działalności – projektowanie i
        dostawa gotowego produktu.
      </p>
    </div>
  );
};

export default AboutCompany;
