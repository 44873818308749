import React from "react";

const TypoTestPage = () => {
  return (
    <div style={{padding: 16}}>
      <h1>H1 header</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam cursus
        dui vitae augue euismod, sed scelerisque velit suscipit. Quisque viverra
        nulla leo, vitae hendrerit mi gravida vel. Ut quis ligula a magna
        consequat tempus a at lorem. Etiam ultrices eu felis bibendum commodo.
        Fusce faucibus quis enim eget rhoncus. Duis pretium rhoncus odio, et
        malesuada urna mattis quis. Etiam consectetur eros ut tempor ornare. Sed
        a erat at enim congue dignissim et et sapien.
      </p>
      <p>
        Quisque ultricies, urna id consectetur auctor, ligula sapien feugiat
        ipsum, et lacinia justo massa non velit. Fusce nec est elementum enim
        ullamcorper egestas in sed quam. Donec faucibus velit placerat molestie
        ultrices. Maecenas erat nunc, ultrices ac accumsan in, condimentum in
        nibh. Praesent viverra est massa, ut cursus sapien tempor a. Quisque
        condimentum malesuada interdum. Pellentesque sed interdum ligula, in
        efficitur lectus. In diam nunc, iaculis eu auctor ac, rutrum non lectus.
        Fusce euismod, erat eu tincidunt ultrices, est urna venenatis dolor, non
        congue massa magna eget neque. Vivamus feugiat, risus ac rutrum
        ultrices, ipsum arcu tincidunt velit, a luctus lorem mauris nec metus.
        Suspendisse eu fringilla erat. Etiam vehicula elit et cursus varius.
        Proin tellus sem, cursus eu hendrerit eu, convallis sit amet ante.
      </p>

      <h2>H2 Header</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam cursus
        dui vitae augue euismod, sed scelerisque velit suscipit. Quisque viverra
        nulla leo, vitae hendrerit mi gravida vel. Ut quis ligula a magna
        consequat tempus a at lorem. Etiam ultrices eu felis bibendum commodo.
        Fusce faucibus quis enim eget rhoncus. Duis pretium rhoncus odio, et
        malesuada urna mattis quis. Etiam consectetur eros ut tempor ornare. Sed
        a erat at enim congue dignissim et et sapien.
      </p>

      <h3>H3 header</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam cursus
        dui vitae augue euismod, sed scelerisque velit suscipit. Quisque viverra
        nulla leo, vitae hendrerit mi gravida vel. Ut quis ligula a magna
        consequat tempus a at lorem. Etiam ultrices eu felis bibendum commodo.
        Fusce faucibus quis enim eget rhoncus. Duis pretium rhoncus odio, et
        malesuada urna mattis quis. Etiam consectetur eros ut tempor ornare. Sed
        a erat at enim congue dignissim et et sapien.
      </p>

      <h4>H4 header</h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam cursus
        dui vitae augue euismod, sed scelerisque velit suscipit. Quisque viverra
        nulla leo, vitae hendrerit mi gravida vel. Ut quis ligula a magna
        consequat tempus a at lorem. Etiam ultrices eu felis bibendum commodo.
        Fusce faucibus quis enim eget rhoncus. Duis pretium rhoncus odio, et
        malesuada urna mattis quis. Etiam consectetur eros ut tempor ornare. Sed
        a erat at enim congue dignissim et et sapien.
      </p>

      <h5>H5 header</h5>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam cursus
        dui vitae augue euismod, sed scelerisque velit suscipit. Quisque viverra
        nulla leo, vitae hendrerit mi gravida vel. Ut quis ligula a magna
        consequat tempus a at lorem. Etiam ultrices eu felis bibendum commodo.
        Fusce faucibus quis enim eget rhoncus. Duis pretium rhoncus odio, et
        malesuada urna mattis quis. Etiam consectetur eros ut tempor ornare. Sed
        a erat at enim congue dignissim et et sapien.
      </p>

      <h6>H5 header</h6>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam cursus
        dui vitae augue euismod, sed scelerisque velit suscipit. Quisque viverra
        nulla leo, vitae hendrerit mi gravida vel. Ut quis ligula a magna
        consequat tempus a at lorem. Etiam ultrices eu felis bibendum commodo.
        Fusce faucibus quis enim eget rhoncus. Duis pretium rhoncus odio, et
        malesuada urna mattis quis. Etiam consectetur eros ut tempor ornare. Sed
        a erat at enim congue dignissim et et sapien.
      </p>
      
      <p>Margin values:</p>
      <ul>
        <li>8px</li>
        <li>16px</li>
        <li>etc.</li>
        <li>80px</li>
      </ul>

      <p>Font values</p>

      <ol>
        <li>14px</li>
        <li>16px</li>
        <li>18px</li>
        <li>28px</li>
        <li>32px</li>
        <li>36px</li>
        <li>40px</li>
      </ol>
    </div>
  );
};

export default TypoTestPage;
