import React from "react";
import "./index.css";

const TextTiles = ({ texts }: { texts: string[] }) => {
  return (
    <div className="cmp-text-tiles">
      {texts.map((txt) => (
        <div
          key={txt.toLowerCase().replace(" ", "-")}
          className="cmp-text-tiles__tile"
        >
          <h3>{txt}</h3>
        </div>
      ))}
    </div>
  );
};

export default TextTiles;
