import React from "react";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import GalleryApp from "../../components/GalleryApp";
import "./index.css";

const ImplementationPage = () => {
  return (
    <>
      <NavBar />
      <div className="cmp-container">
        <section id="implementation-gallery">
          <h1>Galeria</h1>
          <GalleryApp />
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ImplementationPage;
