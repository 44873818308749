import React from "react";
import "./index.css";

type OfferTileItemProps = React.PropsWithChildren<{
  title: string;
  href: string;
  img: string;
}>;

const OfferTileItem = ({ children, title, img, href }: OfferTileItemProps) => {
  return (
    <a className="cmp-offer-tile-item" href={href}>
      <div className="cmp-offer-tile-item__content">
        <div className="cmp-offer-tile-item__img-wrapper">
          <img src={img} alt="" />
        </div>
        <h2>{title}</h2>
        <div className="cmp-offer-tile-item__children-body">{children}</div>
      </div>
      <a href={href}>Szczegóły</a>
    </a>
  );
};

export default OfferTileItem;
