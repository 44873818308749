import React from "react";
import "./index.css";
import TextTiles from "../../../../components/TextTiles";
import Footer from "../../../../components/Footer";
import OfferPageTitle from "../../../../components/OfferPageTitle";
import NavBar from "../../../../components/NavBar";
import { useNavItems } from "../useNavItems";
import SubPageNavigation from "../../../../components/SubPageNavigation";
import GalleryCarousel from "../../../../components/GalleryCarousel";
import { GALLERY_PRZYRZADY_SPAWALNICZE } from "../../../../store";

const Page = () => {
  const { navItems } = useNavItems();
  return (
    <>
      <NavBar />
      <div className="cmp-container">
        <OfferPageTitle
          title="Przyrządy produkcyjne"
          current="/oferta/przyrzady-produkcyjne"
        />
        <SubPageNavigation
          navIems={navItems}
          img="/img/1.jpg"
          currentSection="przyrzady-spawalnicze"
        >
          <section id="przyrzady-spawalnicze">
            <h3>PRZYRZĄDY SPAWALNICZE</h3>
            <p>
              mieszczą się w kategorii UCHWYTY I PRZYRZĄDY OBRÓBKOWE, ale ze
              względu na specjalne wymagania i charakterystyczną funkcję jest to
              nasza specjalna kategoria. Zadaniem przyrządów spawalniczych jest
              zapewnienie:
            </p>
            <ul>
              <li>wysokiej jakości i precyzji połączeń</li>
              <li>powtarzalności</li>
              <li>
                skrócenie czasu potrzebnego na mocowanie elementów składowych
              </li>
              <li>
                kosztów adekwatnych do oczekiwanej jakości i wielkości produkcji
              </li>
            </ul>
            <p>
              Projektujemy i dostarczamy zarówno ręczne przyrządy wykorzystujące
              zarówno mechaniczne dociskacze jak i pneumatyczne i hydrauliczne
              systemy mocowań wykorzystywane przy spawaniu ręcznym jak i w
              zrobotyzowanych celach spawalniczych.
            </p>
            <GalleryCarousel content={GALLERY_PRZYRZADY_SPAWALNICZE.content} />
            <TextTiles
              texts={[
                "SZYBKI I POWTARZALNY MONTAŻ",
                "PROSTE I SOLIDNE ROZWIĄZANIA",
                "FUNKCJONALNOŚĆ ERGONOMIA EKONOMIA",
              ]}
            />
          </section>
        </SubPageNavigation>
      </div>
      <Footer />
    </>
  );
};

export default Page;
