import React, { useState } from "react";
import { GALLERY, GalleryObject } from "../../store";
import "./index.css";
import { PhotoProvider, PhotoView } from "react-photo-view";

const GalleryApp = () => {
  const [openItems, setOpenItems] = useState<
    GalleryObject | { src: string; thumb?: string }[]
  >(GALLERY);
  return (
    <div className="cmp-gallery-app">
      <button
        className="cmp-gallery-app__go-back"
        onClick={() => setOpenItems(GALLERY)}
      >
        Wróć do głównego katalogu
      </button>
      <div className="cmp-gallery-app__container">
        <PhotoProvider>
          {openItems.map((item) => {
            if ("src" in item) {
              return (
                <PhotoView key={item.src} src={item.src}>
                  <img
                    className="cmp-gallery-app__image-item"
                    src={item.thumb || item.src}
                    alt=""
                  />
                </PhotoView>
              );
            }
            return (
              <button
                className="cmp-gallery-app__folder-cover"
                onClick={() => setOpenItems(item.content)}
              >
                <img
                  className="cmp-gallery-app__image-item"
                  alt=""
                  src={item.thumb}
                  key={item.id}
                />
                <h3>{item.label}</h3>
              </button>
            );
          })}
        </PhotoProvider>
      </div>
    </div>
  );
};

export default GalleryApp;
