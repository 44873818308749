import React from "react";
import "./index.css";

type Props = {
  content: {
    signature: string;
    title: string;
    body: JSX.Element;
  }[];
};

const MobileList = ({ content }: Props) => {
  return (
    <div className="cmp-step-vertical-list">
      {content.map((item) => (
        <div
          key={`${item.signature}-${item.title}`}
          className="cmp-step-vertical-list__item"
        >
          {item.signature && (
            <div className="cmp-step-vertical-list__signature-container">
              <span className="cmp-step-vertical-list__signature">
                {item.signature}
              </span>
            </div>
          )}
          <h4>{item.title}</h4>
          <div className="cmp-step-vertical-list__body">{item.body}</div>
        </div>
      ))}
    </div>
  );
};

export default MobileList;
