import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import RightImageHeader from "../../components/RightImageHeader";
import Map from "../../components/Map";
import "./index.css";
import ContanctDetails from "../../components/ContanctDetails";
import GeneralCompanyDetail from "../../components/GeneralCompanyDetail";

const ContactPage = () => {
  return (
    <>
      <NavBar />
      <div className="cmp-container">
        <RightImageHeader text="Kontakt" />
        <GeneralCompanyDetail />
        <ContanctDetails />
        <Map />
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
