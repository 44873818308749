import ContentCard from "./FullCards/ContentCard";
import DefaultCard from "./FullCards/DefaultCard";
import "./contentSection.css";

type Props = {
  contents: {
    img: string;
    title: string;
    href: string;
    body: JSX.Element;
  }[];
  seleted: string | undefined;
};

const ContentSection = ({ contents, seleted }: Props) => {
  return (
    <>
      <DefaultCard display={seleted === undefined} />
      {contents.map((cnt) => (
        <ContentCard key={cnt.img} content={cnt} display={seleted === cnt.img} />
      ))}
    </>
  );
};

export default ContentSection;
