import React from "react";
import { ReactComponent as SmallLogoSVG } from "./icon-small.svg";
import { ReactComponent as FullLogoSVG } from "./icon-full.svg";
import "./index.css";

const ResponsiveLogo = () => {
  return (
    <div className="cmp-responsive-logo">
      <div className="cmp-responsive-logo--full">
        <FullLogoSVG />
      </div>
      <div className="cmp-responsive-logo--mobile">
        <SmallLogoSVG />
      </div>
    </div>
  );
};

export default ResponsiveLogo;
