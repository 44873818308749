import React from "react";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import "./index.css";
import SignatreGridList from "../../components/SignatreStepList";

const HowWeOperatePage = () => {
  return (
    <>
      <NavBar />
      <div className="cmp-container cmp-how-we-operate">
        <h1 style={{ marginTop: "2rem" }}>Jak działamy</h1>
        <section id="description">
          <p>
            Wszystkie realizowane projekty oparte są o metodykę tzw. KAMIENI
            MILOWYCH oraz uniwersalne założenia metodyki zarządzania projektami.
            Takie systemowe podejście gwarantuje klientom przejrzysty proces
            nastawiony na realizację tego na co się na samym początku umawiamy.
            Przedstawiony schemat jest bazą do dalszych uzgodnień, a każdy jego
            etap może i powinien być dostosowany do specyfiki konkretnego
            projektu.
          </p>
        </section>
        <section id="tiles">
          <SignatreGridList />
        </section>
        <section id="">
          <h2>UWAGI</h2>
          <>
            <p>
              W zależności od typu projektu powyższe punkty są uzupełnione o
              wymagane dokumenty i/lub działania jak np.
            </p>
            <ul>
              <li>Symulacje</li>
              <li>Analizy D-FMEA, P-FMEA</li>
              <li>Testy prototypów</li>
            </ul>
            <p>
              Klientom, którzy nie mają gotowych specyfikacji pomagamy w
              spisaniu zakresu projektu i oczekiwań z tym związanych, po to aby
              możliwie dokładnie określić zakres dostawy i spełnić oczekiwania
              klienta.
            </p>
            <ul>
              <li>
                Nasze zobowiązanie do dostarczenia klientowi produktu lub usługi
                w jakości, terminie i cenie określonej w zamówieniu jest
                wartością nadrzędną.
              </li>
              <li>
                O ew. problemach z dotrzymaniem warunków dostawy niezwłocznie
                informujemy klienta oraz przedstawiamy propozycję alternatywną -
                jesteśmy transparentni i na życzenie przedstawiamy raporty z
                postępów w określonych terminach.
              </li>
            </ul>
          </>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default HowWeOperatePage;
