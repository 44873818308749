import React from "react";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import "./index.css";
import OfferTiles from "../../components/OfferTiles";

const OfferPage = () => {
  return (
    <>
      <NavBar />
      <section id="offer">
        <h2>Nasza oferta</h2>
        <OfferTiles/>
      </section>
      <Footer />
    </>
  );
};

export default OfferPage;
